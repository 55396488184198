import './index.scss';
import '../../assets/scripts/particles.js';
// 粒子动效
particlesJS('particles-js', {

});

$(document).ready(function () {
    getfooterH()
});
$(window).resize(function () {
    getfooterH()
});

//获取footer高度，设置内容padding-bottom
function getfooterH() {
    let footerH = $('.fixed-footer').outerHeight()
    $('.comp-root').css('padding-bottom', footerH + 'px')
}

$('.footer .backtop').on('click',function(){
    $('html,body').animate({ scrollTop: 0 }, {duration: 500,easing: "swing"})
})
