module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if(!imgpath) var imgpath = ''
if(!title1) var title1 = ''
if(!title2) var title2 = ''
;
__p += '\r\n<section class="full-banner">\r\n    <div class="banner-text">\r\n        <div class="en-title"><p>' +
((__t = ( title1 )) == null ? '' : __t) +
'</p></div>\r\n        <div class="cn-title">\r\n            <div class="location">\r\n                <a href="#">首页</a><span>-</span>\r\n                <a href="#">产品与解决方案</a><span>-</span>\r\n                <a href="#">综合电子解决方案</a>\r\n            </div>\r\n            <div class="t">' +
((__t = ( title2 )) == null ? '' : __t) +
'</div>\r\n        </div>\r\n    </div>\r\n    <div class="banner-img">\r\n        <b style="background-image: url(' +
((__t = ( imgpath )) == null ? '' : __t) +
');"></b>\r\n    </div>\r\n</section>';

}
return __p
}