module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer>\r\n    <div class="footer fixed-footer container">\n        <div id="particles-js"></div>\r\n        <div class="backtop"><i class="iconfont icon-jiantou_xia"></i></div>\r\n        <div class="footer-link">\r\n            <a href="#">产品与解决方案</a>\r\n            <a href="#">应用领域</a>\r\n            <a href="#">关于我们</a>\r\n            <a href="#">资讯动态</a>\r\n            <a href="#">加入我们</a>\r\n            <a href="#">联系方式</a>\r\n        </div>\r\n        <div class="footer-cont">\r\n            <div class="footer-left">\r\n                <div class="footer-logo"><img src="' +
((__t = (require('./images/footer_logo.png'))) == null ? '' : __t) +
'" alt=""></div>\r\n                <div class="website"><a href="http://www.rc-xw.com/">www.rc-xw.com</a></div>\r\n                <div class="copyright">Copyright © 2022 青岛融创信为技术有限公司 版权所有 | <a href="#">技术支持</a></div>\r\n            </div>\r\n            <div class="footer-right">\r\n                <div class="phone">0532-66028857</div>\r\n                <div class="address">公司地址：山东省青岛市高新区华贯路819号联东U谷产业园8#-1</div>\r\n                <div class="webmap"><a href="javascript:void(0);" data-open="map">网站地图</a></div>\r\n                <div class="copyright">Copyright © 2022 青岛融创信为技术有限公司 版权所有 | <a href="#">技术支持</a></div>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</footer>';

}
return __p
}