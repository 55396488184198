import './index.scss'


$(document).scroll(function () {
    var scroH = $(document).scrollTop();
    var viewH = $(window).height();
    var contentH = $(document).height();

    if ($('.full-banner').length > 0){
        $('.full-banner .en-title p').css('transform', 'translateX(-' + scroH / 3 +'px)')
        $('.full-banner .banner-img b').css('transform', 'translateY(' + scroH / 5 + 'px)')
    }
})