import './index.scss'


$(document).scroll(function () {
    var scroH = $(document).scrollTop();
    var viewH = $(window).height();
    var contentH = $(document).height();

    if ($('.half-banner').length > 0){
        $('.half-banner .right-img b').css('transform', 'translateY(' + scroH / 3 + 'px)')
    }
})