import './index.scss'

var beforeScroH = $(document).scrollTop();
$(document).scroll(function () {
    var scroH = $(document).scrollTop();
    var viewH = $(window).height();
    var contentH = $(document).height();
    // if(scroH >100){
    //     $('header').addClass('scroll-header')
    // }else{
    //     $('header').removeClass('scroll-header')
    // }

    //判断页面是上滚还是下滚
    var delta = scroH - beforeScroH;
    if (delta > 0 && scroH > 100) {
        $('.header').addClass('hide')
    }
    if (delta < 0) {
        $('.header').removeClass('hide')
    }
    beforeScroH = scroH;
    if (contentH - (scroH + viewH) <= 100){}
    if (contentH = (scroH + viewH)){}
});

$('[data-open="cmenu"]').on('click', function () {
    if ($('.header .hamburger').hasClass('active')) {
        $('.header .hamburger').removeClass('active')
        $('header .mob-nav').removeClass('active')
        $('.root-mask').fadeOut()
    } else {
        $('.header .hamburger').addClass('active')
        $('header .mob-nav').addClass('active')
        $('.root-mask').fadeIn()
    }
})

// pc端二级菜单效果
var handle = null;
$(".header .right-nav ul li").hover(function () {
    $(".header .right-nav ul li.active").addClass('not-active')
    $(this).addClass('hover').siblings().removeClass('hover')
    if ($(this).find('.sub-right a').length > 0) {
        let subH = $(this).find('.sub-nav').outerHeight()
        $('.header').stop().animate({ paddingBottom: subH + 'px' }, 300);
    } else {
        $('.header').stop().animate({ paddingBottom: '0px' }, 300);
        $('.root-mask').stop().fadeOut()
    }
    handle = setTimeout(() => {
        if ($(this).find('.sub-right a').length > 0) {
            $('.root-mask').stop().fadeIn()
        }
    }, 300);
},function(){
        clearTimeout(handle);
        $(".header .right-nav ul li.active").removeClass('not-active')
        $(".header .right-nav ul li").removeClass('hover')
        $('.header').stop().animate({ paddingBottom: '0px' }, 300);
})

// 解决方案子栏目左侧图片切换更新
$('.right-nav ul li:nth-child(2) .sub-nav .sub-right .sub-item:nth-child(1)').hover(function() {
    $('.select1').css("display","flex")
    $('.select2').css("display","none")
    $('.select3').css("display","none")
});
$('.right-nav ul li:nth-child(2) .sub-nav .sub-right .sub-item:nth-child(2)').hover(function() {
    $('.select1').css("display","none")
    $('.select2').css("display","flex")
    $('.select3').css("display","none")
});
$('.right-nav ul li:nth-child(2) .sub-nav .sub-right .sub-item:nth-child(3)').hover(function() {
    $('.select1').css("display","none")
    $('.select2').css("display","none")
    $('.select3').css("display","flex")
});

$(".header .right-nav ul").mouseleave(function () {
    $(".header .right-nav ul li.active").removeClass('not-active')
    $(".header .right-nav ul li").removeClass('hover')
    $('.root-mask').stop().fadeOut()
})

$('.root-mask').hover(function(){
    $(".header .right-nav ul li.active").removeClass('not-active')
    $('.header').stop().animate({ paddingBottom: '0px' }, 300);
    $('.header .hamburger').removeClass('active')
    $('header .mob-nav').removeClass('active')
    $('.root-mask').stop().fadeOut()
})

//移动端有子菜单的显示箭头
$('header .mob-nav ul li').each(function(){
    if ($(this).find('.sub-nav a').length > 0){
        $(this).find('.nav-title .iconfont').addClass('show')
    }
})
//点击移动端导航列表展开子项
$('header .mob-nav ul li .nav-title .iconfont').on('click', function () {
    if (!$(this).parents('li').hasClass('active') && $(this).parents('li').find('.sub-nav a').length != 0) {
        $(this).parents('li').addClass('active').siblings().removeClass('active')
        $(this).parents('li').find('.sub-nav').slideDown(300)
        $(this).parents('li').siblings().find('.sub-nav').slideUp(300)
    }
})
